import { Component } from "@angular/core";
import { SoundService } from "./sound.service";

@Component({
    templateUrl: 'notification-send-dialog.component.html',
    styles: [
        `
            .mat-form-field {
                width: 100%;
                max-width: none;
            }
            .message {
                margin: 6px 0;
                padding: 6px;
                background-color: #F8F8F8;
                /* box-shadow copied from mat-raised-button style */
                box-shadow: 0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12)
            }
            .error {
                background-color: #f44336;
                color: white;
            }
            mat-dialog-content {
                width: 400px;
                max-width: 400px;
            }
        `
    ],
})
export class NotificationSendDialogComponent
{
    constructor(private soundService: SoundService)
    {  }

    // state tracking
    sending = false;
    formDisabled = false;

    // input from the dialog
    topic = "dev";
    message = "";
    soundUid = "";

    // output on the dialog
    sendingMessage = "";
    endpointMessage = "";
    errorMessage = "";

    // returned to caller
    public dialogResult = false; // true when a notif successfully sent

    onSubmit(): void
    {
        if (!this.topic || this.topic.length == 0)
        {
            this.errorMessage = "Notification topic could not be determined. Please choose Dev, Admin, or All.";
            return;   
        }
        if (!this.soundUid && !this.message)
        {
            this.errorMessage = "Please enter at least one of Message or Sound UID.";
            return;
        }

        this.errorMessage = "";

        this.sendingMessage = "Sending '" + this.message + "' to topic: <strong>" + this.topic + "</strong>";
        if (this.soundUid) this.sendingMessage += " (uid: " + this.soundUid + ")";

        this.sending = true;
        this.formDisabled = true;

        this.soundService.sendAdminNotification(this.message, this.soundUid, this.topic)
            .then(response => {
                this.sending = false;
                let reply = "<b>Success!</b> Notification sent to Endpoint: <i>";
                if(response.Endpoint) reply += response.Endpoint;
                reply += "</i><br/> Url: <i>";
                if(response.Url) reply += response.Url;
                reply += "</i>";
                this.endpointMessage = reply;

                this.dialogResult = true; // let notifications component know api refresh is needed
            })
            .catch(err => {
                this.sending = false;
                this.formDisabled = false;
                this.errorMessage = err + "";
            });
    }

}