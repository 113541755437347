// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
            .mat-form-field {
                width: 100%;
                max-width: none;
            }
            .message {
                margin: 6px 0;
                padding: 6px;
                background-color: #F8F8F8;
                /* box-shadow copied from mat-raised-button style */
                box-shadow: 0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12)
            }
            .error {
                background-color: #f44336;
                color: white;
            }
            mat-dialog-content {
                width: 400px;
                max-width: 400px;
            }
        `, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
