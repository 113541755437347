import { Component, Inject } from "@angular/core";
import { MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { TMApiService } from '../tmapi.service';

@Component({
  selector: 'app-promo-dialog',
  templateUrl: './promo-dialog.component.html',
  styleUrls: ['./promo-dialog.component.css']
})

export class PromoDialogComponent {

  title="Add Promo";
  error = "";
  edit=false;

  name="";
  value=0.0;
  enabled=true;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private tmapiService: TMApiService,
    private dialogRef: MatDialogRef<PromoDialogComponent>) 
    { 
      if (data)
      {
        //console.log("EDIT = " + JSON.stringify(data));
        this.title = "Edit Promo";
        this.name = data.promo_id;
        this.value = data.value;
        this.enabled = (data.enabled == 'true' || data.enabled == 1);
        this.edit = true;
      }
    }

    onClose()
    {
      // nop for result right now
      this.dialogRef.close();
    }

    onSubmit() 
    {
      this.name = this.tmapiService.fixPromoCode(this.name);
      if (this.name.length == 0 || this.value < 0 || this.value > 1)
      {
        this.error = "Please input valid values.";
        return;
      }

      this.tmapiService.putAdminPromo(this.name, this.value, this.enabled)
                      .then( data=> this.dialogRef.close(data))
                      .catch(error => {
                          console.error("An error occurred", error);
                          this.error = error;
                        }
                      );      
    }

    onRemove()
    {
      if (this.name.length == 0)
      {
        this.error = "Invalid id for removal.";
        return;
      }

      this.tmapiService.deleteAdminPromo(this.name)
                      .then( data => this.dialogRef.close(data))
                      .catch(error => console.error("An error occurred", error));    
    }

    onPromoInput()
    {
      this.clearError();
      this.name = this.tmapiService.fixPromoCode(this.name);
    }

    onValueInput()
    {
      this.clearError();
      if (this.value < 0) this.value = 0;
      else if (this.value > 1) this.value = 1;
    }
    clearError() 
    {
      this.error="";
    }
}
