import { Component, Inject } from "@angular/core";
import { MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";

import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

import { SoundService } from "../sound.service";
import { User } from "../user";

@Component({    
    templateUrl: "./user-edit-dialog.component.html",
    styleUrls: ["./user-edit-dialog.component.css"],
})
export class UserEditDialogComponent
{
    constructor(
        @Inject(MAT_DIALOG_DATA) public dialogData: any,
        private soundService: SoundService) {
        this.user = dialogData as User;
     }

    public user: User;
    public displayMsg = "";

    // fields that can be edited
    public userData = {
        Uuid: "",
        NameFirst: "",
        NameLast: "",
        Nickname: "",
        UserName: "",
        UserDescription: "",
        UserWebsite: "",
        Badges: "",
    };
    public roleValue = 0;

    // data table
    displayedColumns = ['add', 'remove', 'label'];
    data =[
        {
            add: 1,
            del: -1,
            label: "Guest (1)"
        },
        {
            add: 2,
            del: -2,
            label: "Mix Upload (2)"
        },
        {
            add: 4,
            del: -4,
            label: "Sound Upload (4)"
        },
        {
            add: 8,
            del: -8,
            label: "Moderator (8)"
        },
        {
            add: 16,
            del: -16,
            label: "Subscriber (16)"
        },
        {
            add: 32,
            del: -32,
            label: "Shadow Ban (32)"
        },
        {
            add: 64,
            del: -64,
            label: "No Chatter (64)"
        },
        {
            add: 512,
            del: -512,
            label: "Admin (512)"
        },
        {
            add: 2048,
            del: -2048,
            label: "Block (2048)"
        },
        {
            add: 4096,
            del: -4096,
            label: "Delete (4096)"
        },
    ];

    ngOnInit(): void {
        this.userData.Uuid = this.user.Uuid;
        this.userData.NameFirst = this.user.NameFirst;
        this.userData.NameLast = this.user.NameLast;
        this.userData.Nickname = this.user.Nickname;
        this.userData.UserName = this.user.UserName;
        this.userData.UserWebsite = this.user.UserWebsite;
        this.userData.UserDescription = this.user.UserDescription;
        this.userData.Badges = this.user.Badges;
    }
  
    onSubmit(): void {        
        this.soundService.updateAdminUser(this.userData, this.roleValue)
            .then(resp => {
                let user = resp['User'];
                if (user) {
                    this.user = user;
                }
                let message = resp['Message'];
                if (!message || message.length == 0) {
                    message = "User updated!";
                }
                this.setMessage(message);
            })
            .catch(error => this.setError(error));
    }

    setError(err: any) {
        console.error("Error during query: ", err);
        this.displayMsg = err['Message'];
      }

    setMessage(msg: string): void {
        msg = msg.replace(/,/g, ", ");
        msg = msg.replace(": ", ": <br/>");
        this.displayMsg = msg;
    }
}