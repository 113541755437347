import { Component, Inject } from "@angular/core";
import { MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { TMApiService } from '../tmapi.service';

@Component({
  selector: 'app-details-dialog',
  templateUrl: './store-details-dialog.component.html',
  styleUrls: ['./store-details-dialog.component.css']
})

export class StoreDetailsDialogComponent {

  title = "Details";
  dataType = "order";

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private tmapiService: TMApiService,
    private dialogRef: MatDialogRef<StoreDetailsDialogComponent>) 
    { 
      //console.log("Data = " + JSON.stringify(data));
      if (this.data.Uid != undefined)
      {
        this.title = "Sound Details";
        this.dataType = "sound";
      }
      else if (this.data.order_id != undefined && this.data.order_total != undefined)
      {
        this.title = "Order Details";
        this.dataType = "order";
      }
      else if (this.data.id != undefined && this.data.url_id != undefined)
      {
        this.title = "Download Details";
        this.dataType = "log";
      }
      else if (this.data.url_id)
      {
        this.title = "URL Details";
        this.dataType = "url";
      }
      else if (this.data.promo_id)
      {
        this.title = "Promo Details";
        this.dataType = "promo";
      }
    }

    getFileInfoList(sound: any): string
    {
      if (this.dataType !== 'sound') return "No Data";
      if (sound == undefined) return "No Data";

      // expecting an array in JSON format for FileInfo field.
      let fileInfo = JSON.parse(sound["FileInfo"]);
      if (fileInfo == undefined || fileInfo.length == 0) return "No Data";

      var result = "";
      for (var i = 0; i < fileInfo.length; i++)
      {
        let info = fileInfo[i];
        if (result.length > 0) result += "<br/>";
        result += "name: " + info.name + " duration: " + info.duration + " size: " + Math.round(info.size/1024/1024).toFixed(0) + "MB";

      }

      return result;
    }

    onClose()
    {
      // nop for result right now
      this.dialogRef.close("");
    }
}
