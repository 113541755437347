import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { BehaviorSubject, Observable } from 'rxjs';
import { CookieService, CookieOptions } from 'ngx-cookie';
import { AppConfig } from './AppConfig';

import { User } from './user';

// documentation refs:
// https://github.com/angular/angularfire/blob/master/docs/auth.md
// https://firebase.google.com/docs/auth/web/start

// Stub class for implementing authentication backed by firebase
@Injectable()
export class MarketAuth implements OnDestroy
{
    // active user ( new method: see https://blog.angular-university.io/how-to-build-angular2-apps-using-rxjs-observable-data-services-pitfalls-to-avoid/ )
    private _user: BehaviorSubject<User> = new BehaviorSubject(null);
    public readonly user: Observable<User> = this._user.asObservable();
    public getUser() : Readonly<User> { return this._user.getValue(); }
    private marketToken: string;

    constructor(
        private http: HttpClient,
        private cookieService: CookieService,
        private config: AppConfig) {

        // check cookie service from cross subdomain activation
        if (this.isAuthenticated() == false)
        {
            let token = this.cookieService.get("token");
            //console.log("Checking cookie token: " + token);
            if (token && token.length > 0) {
                console.log("Initializing token from cookie: " + token);
                this.setToken(token);
            }
        }
    }

    ngOnDestroy(): void {
    }

    logoff() : void {

        // clear market token to complete sign out
        this.marketToken = "";
        this._user.next(null);
        this.updateLoginState();
    }

    isAuthenticated() : boolean {
        return !!this.getToken();
    }

    getToken() : string {
        return this.marketToken;
    }

    setToken(token: string) : void {
        this.marketToken = token;
        this.updateLoginState();
    }

    headers() : any {
        return { headers: this.authHeaders()};
    }

    authHeaders() : HttpHeaders {
        return new HttpHeaders().set(
            "Authorization", "Bearer " + this.getToken()
        );
    }

    updateLoginState()
    {
        let options : CookieOptions = { path: "/", domain: location.hostname };
        if (this.isAuthenticated() == false) {
            this.cookieService.remove("token", options);
            this._user.next(null);
        } else {
            // update status to get user object which includes name, avatar, and if admin
            this.getStatus().then(status => {
                //console.log("Status response: " + JSON.stringify(status));
                this.cookieService.put("token", this.getToken(), options);
                this._user.next(status)
            });
        }
    }

    private async getBearerToken(userData: any) : Promise<any> {
        const url = `${this.config.host()}/auth/firebase/mobile`;
        return this.http.post(url, userData).toPromise();
    }

    private async getStatus(): Promise<any> {
        const url = `${this.config.host()}/api/status`;
        return this.http.get(url, this.headers()).toPromise();
    }

    private handleError(err: any): Promise<any>
    {
      console.error('An error occurred', err);

      // pull out the most specific error message possible (market returns error.Message and firebase returns error.message)
      var msg : string = null;
      if (err.error)
      {
          msg = err.error.Message || err.error.message;
      }
      if (msg == null)
      {
          msg = err.Message || err.message || err.statusText;
      }
      if (msg == null)
      {
          msg = "Unknown";
      }

      // pass along the best error message to the application
      return Promise.reject(msg);
    }
}