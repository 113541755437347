import { Component, Inject } from "@angular/core";
import { SoundService } from "./sound.service";
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from "@angular/material/legacy-dialog";
import { Setting } from "./setting";

@Component({    
    templateUrl: "./setting-dialog.component.html",
})
export class SettingDialogComponent
{
    public title = "Add Setting";

    public setting = new Setting();

    public edit = false;
    
    public badInput = false;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private soundService: SoundService,
        private dialogRef: MatDialogRef<SettingDialogComponent>)
    {
        if (data)
        {
            if (data.edit && data.edit == true) 
            {
                this.title = "Edit Setting";
                this.edit = true;
            }
            if (data.setting)
            {
                console.log("Parsing setting");
                this.setting.Name = data.setting.Name;
                this.setting.Value = data.setting.Value;
            }
        }
    }

    clearError(): void
    {
        this.badInput = false;
    }

    onSubmit(): void
    {
        if (!this.setting || !this.setting.Name)
        {
            this.badInput = true;
            return;
        }

        console.log("adding setting name=" + this.setting.Name + " value=" + this.setting.Value);
        
        this.soundService.putAdminSetting(this.setting)
        .then(settings => { 
            console.log("setting added " + JSON.stringify(settings));
            this.dialogRef.close(settings);
        })
        .catch(error => {
            console.error('An error occurred', error);                 
            return Promise.reject(error.message || error);
        });

    }
}