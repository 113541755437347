// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `hr { 
    border: 1px solid #DADADA; 
    border-bottom-width: 0;
}

.container {
    width:100%;
}

.radio-cell {
    display: inline-block;
    font-size: 100%;
    text-align: center;
    width: 16px;
}

.radio-label {
    display: inline-block;
    margin-left: 32px;
}

.message {
    padding: 3px 8px;
    background-color: #FEE;
    font-size: 80%;
    font-weight: 500;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
