import { Component, ViewContainerRef, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params }   from '@angular/router';
import { SoundService } from './sound.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit
{
  title = 'Market Admin';

  constructor(private route: ActivatedRoute,
              //public toast: ToastsManager, 
              private vcr: ViewContainerRef,
              public soundService: SoundService,
              private router: Router) 
  {
    //this.toast.setRootViewContainerRef(vcr);
  }

  ngOnInit(): void
  {
  }

  logout(): void
  {
    this.soundService.logoff();
    this.router.navigateByUrl("/");
  }
}
