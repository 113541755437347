import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params }   from '@angular/router';
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";

import { SoundService } from './sound.service';

// cdk table
import { DataSource } from '@angular/cdk/table';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs';
//import 'rxjs/add/operator/startWith';
//import 'rxjs/add/observable/merge';
//import 'rxjs/add/operator/map';

// data
import { Setting } from './setting';
import { SettingDialogComponent } from './setting-dialog.component';

@Component({
  selector: 'market-settings',
  templateUrl: './settings.component.html',
})

export class SettingsComponent implements OnInit
{
    title = "Settings";
    working = false;

    // data table
    displayedColumns = ['Name', 'Value', 'Actions'];
    database = new SettingsDatabase();
    dataSource: SettingsDataSource | null;
  
    constructor(private soundService: SoundService,
                private route: ActivatedRoute,
                private dialog: MatDialog)
    {}

    ngOnInit(): void
    {
      this.working = true;
      this.dataSource = new SettingsDataSource(this.database);
      this.refreshSettings();
    }

    refreshSettings()
    {
      console.log("refreshing settings");
      this.soundService.getAdminSettings()
        .then(settings => this.setSettings(settings))
        .catch(err => this.setError(err));      
    }
  
    setSettings(settings: Setting[])
    {
      this.working = false;
      this.database.dataChange.next(settings);
    }
  
    setError(err: any)
    {
      console.info("Error during query!");
      this.working = false;
    }

    addSetting(): void
    {
      console.log("clicked add setting");
      this.dialog.open(SettingDialogComponent).afterClosed()
        .subscribe(result => { 
          if(!result)
          {
            console.log("no setting added - user canceled operation");
          }
          else
          {
            console.log("updating with new settings " + result);
            this.refreshSettings();
          }
        });
    }

    editSetting(row: Setting): void
    {
      console.log("editing setting " + JSON.stringify(row));
      let options = { data: {
        edit: true,
        setting: row
      }};
      this.dialog.open(SettingDialogComponent, options).afterClosed()
        .subscribe(result => {
          
          if(!result)
          {
            console.log("no setting edited - user canceled operation");
          }
          else
          {
            console.log("edited setting " + result);
            this.refreshSettings();
          }
        });
    }
}

export class SettingsDatabase 
{
  dataChange: BehaviorSubject<Setting[]> = new BehaviorSubject<Setting[]>([]);
  get data(): Setting[] { return this.dataChange.value; }
  
  constructor() {}
}

export class SettingsDataSource extends DataSource<any> 
{
  constructor(private _database: SettingsDatabase) 
  {
    super();
  }

  connect(): Observable<Setting[]> 
  {
    return this._database.dataChange;
  }

  disconnect() {}
}

