// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `mat-table
{
    min-width: 500px;
}

.mat-cell
{    
    overflow: hidden;
}

.mat-column-ID 
{ 
    max-width: none;     
    flex: 3 1 0;
}

mat-row .mat-column-ID
{
    font-size: 80%;
}

.mat-column-Name 
{ 
    max-width: none;
    flex: 2 1 0;
}

.mat-column-Dates 
{ 
    max-width: none;
    flex: 2 1 0;
}

.mat-column-Stats 
{ 
    max-width: none;
    flex: 1 1 0;
}

.mat-column-Tokens 
{ 
    max-width: none;
    flex: 1 1 0;
}

.mat-column-Actions 
{ 
    max-width: none;
    flex: 1 1 0;
}

.mat-column-Actions button 
{ 
    margin: 0 auto 0 0;
    padding: 0;
    min-width: 0;
    width: 100%;
    max-width: 160px;
}

.date-label
{
    display: inline-block;
    width: 50px;
}

.date
{
    white-space: nowrap;
}

.text-muted
{
    font-size: 12px;
}

.app-toolbar-search 
{
  height: 100%;
  color: #333;
  border: 1px solid #D0D0D0;
  border-radius: 100px;
  background-color: #fafafa;
  padding: 5px 16px;

  flex: 1 1 auto;

  min-width: 120px;
  max-width: 300px;

  outline: none;
}

.page-buttons
{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.page-buttons > button
{
    margin: 5px;
}

.small-button
{
    min-width: 50px;
    max-width: 80px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
