import { Component, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";

import { SoundService } from './sound.service';
import { AppConfig } from './AppConfig';

// cdk table
import { DataSource } from '@angular/cdk/table';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs';

// data
import { Tag } from './tag';
import { TagAddDialogComponent } from './tag-add-dialog.component';
import { YesNoDialogComponent } from './yes-no-dialog.component';

@Component({
  selector: 'market-tags',
  templateUrl: './tags.component.html',
})

export class TagsComponent implements OnInit
{
    title = "Tags";
    working = false;
    
    // data table
    displayedColumns = ['Image', 'TagLabel', 'TagValue', 'Sort', 'Description', 'Actions'];
    database = new TagsDatabase();
    dataSource: TagsDataSource | null;
  
    constructor(
      private soundService: SoundService,
      public config: AppConfig,
      private dialog: MatDialog) { }

    ngOnInit(): void
    {
      this.working = true;
      this.dataSource = new TagsDataSource(this.database);
      this.refreshTags();
    }

    refreshTags()
    {
      this.soundService.getAdminTags()
        .then(tags => this.setTags(tags))
        .catch(err => this.setError(err));      
    }
  
    setTags(tags: Tag[])
    {
      this.working = false;
      this.database.dataChange.next(tags);
    }
  
    setError(err: any)
    {
      console.info("Error during query!");
      this.working = false;
    }

    openAddTagDialog(): void
    {
      console.log("clicked add tag");
      this.dialog.open(TagAddDialogComponent).afterClosed()
        .subscribe(result => { 
          if(!result)
          {
            console.log("no tag added - user canceled operation");
          }
          else
          {
            console.log("updating with new tag " + result);
            var curTags = this.database.data;
            // loop to find the first index with a higher sort value
            var i = -1;
            while (++i < curTags.length && curTags[i].Sort <= result.Sort);
            //insert at that position (which may include appending the end of the array)
            curTags.splice(i, 0, result);
            this.database.dataChange.next(curTags);
          }
        });
    }

    editTag(row: Tag): void
    {
      console.log("editing tag " + JSON.stringify(row));
      let options = { data: {
        edit: true,
        tag: row
      }};
      this.dialog.open(TagAddDialogComponent, options).afterClosed()
        .subscribe(result => {
          
          if(!result)
          {
            console.log("no tag edited - user canceled operation");
          }
          else
          {
            console.log("editing tag " + result);
            var curTags = this.database.data;
            // loop to find the item in database array
            for (var i = 0; i < curTags.length; ++i)
            {
              if (curTags[i].Value === result.Value)
              {
                curTags[i] = result;
                break;
              }
            }
            this.database.dataChange.next(curTags);
          }
        });
    }
    
    deleteTag(row: Tag): void
    {
      console.log("deleting tag " + JSON.stringify(row));
      let options = { data: {
        title: "Delete Tag",
        message: "Delete the '" + row.Value + "' tag?"
      }};
      this.dialog.open(YesNoDialogComponent, options).afterClosed()
        .subscribe(result => {
          
            if(!result) return;

            this.soundService.deleteAdminTag(row.Value)
            .then(result => {
              // make sure we removed the correct tag, then update our local database to match
              if(result == row.Value)
              {
                var curTags = this.database.data;
                var i = curTags.indexOf(row);
                if(i >= 0)
                {
                  // remove the tag from the tags array
                  curTags.splice(i, 1);
                }
                // update tags array
                this.setTags(curTags);
              }
            })
            .catch(err => console.error(err));
          });
    }
}

export class TagsDatabase 
{
  dataChange: BehaviorSubject<Tag[]> = new BehaviorSubject<Tag[]>([]);
  get data(): Tag[] { return this.dataChange.value; }
  
  constructor() {}
}

export class TagsDataSource extends DataSource<any> 
{
  constructor(private _database: TagsDatabase) 
  {
    super();
  }

  connect(): Observable<Tag[]> 
  {
    return this._database.dataChange;
  }

  disconnect() {}
}

