import { Injectable }    from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfig } from './AppConfig';

import { Sound, UserSounds } from './sound';
import { Tag } from './tag';
import { Comment } from './comment';
import { Note } from './note';
import { Flag, Flags, FlagResult } from './flag';
import { User } from './user';
import { Setting } from './setting';
import { Subscription } from './subscription';

// login and authentication
import { MarketAuth } from './marketauth.service';

@Injectable()
export class SoundService
{
  // active user
  public authenticated = false;
  public userId : string;
  public displayName : string;
  public avatarUrl : string;
  public isAdmin = false;
  private marketToken = "";

  private itemsUrl    = '/api/items';       // URL to web api recent sounds
  private itemUrl     = '/api/item';        // URL to web api single sound details
  private tagsUrl     = '/api/categories';  // URL to public tags
  private searchUrl   = '/api/search';      // URL to search
  private geoUrl      = '/api/geo';         // URL to geo sounds
  private userUrl     = '/api/user/items';  // URL to user sounds
  private commentsUrl = '/api/comments';    // URL to chatter comments
  private statusUrl   = '/api/status';      // URL to user status
  private flagUrl     = '/api/flag/1';      // URL to for flags (hearts)

  // admin
  private adminItemsUrl          = '/api/admin/items'; // URL to for admin items
  private adminItemUrl           = '/api/admin/item';   // URL to for admin single-item
  private adminFixupUrl          = '/api/admin/fixup'; // URL to for fixup commands
  private adminCommentsUrl       = '/api/admin/comments';
  private adminNotifUrl          = '/api/admin/notifications';
  private adminTagUrl            = '/api/admin/tags';
  private adminSearchUrl         = '/api/admin/search';
  private adminAttachUrl         = '/api/admin/attach';
  private adminAddUrl            = '/api/admin/add';
  private adminUsersUrl          = '/api/admin/users';
  private adminUserUpdateUrl     = '/api/admin/user';
  private adminNotifyUrl         = '/api/admin/notify';
  private adminDownloadUrl       = '/api/admin/download';
  private adminSettingsUrl       = '/api/admin/settings';
  private adminSubscriptionsUrl  = '/api/admin/subscriptions';
  private adminExportUrl         = '/api/admin/export';
  private adminCallUrl           = '/api/admin/call';

  private cachedTags : any;

  constructor(private http: HttpClient,
              private auth: MarketAuth,
              private config: AppConfig)
  {
    this.auth.user.subscribe(user => this.userUpdated(user));
    /*
    // load from local storage
    this.updateLoginState();

    console.log("Created Sound Service: " + this.authenticated);

    // check cookie service from cross subdomain activation
    if (this.authenticated == false || this.isAdmin == false)
    {
        let token = this.cookieService.get("token");
        console.log("Checking cookie token: " + token);
        if (token && token.length > 0)
        {
          console.log("Initializing token from cookie: " + token);
          this.marketToken = token;
          this.updateLoginState();
        }
    }
    */

    // demo data
    // this.userId = "5698390809640960";
    // this.displayName = "Demo";
    // this.avatarUrl = "//www.tmsoft.com/wp-content/uploads/binaural-beats-generator-app-e1488658630157-300x300.png";
    // this.authenticated = true;
    // this.isAdmin = true;
  }

  userUpdated(user: any)
  {
    if (user != null)
    {
      console.log("New user updated:", user)
      this.updateLoginState();
    }
  }

  getHost(): string
  {
    return this.config.host();
  }

  /*
  // mock sound data
	getSounds(): Promise<Sound[]>
	{
    	return Promise.resolve(SOUNDS);
	}
  */

  // -------------- admin commands ---------------

  getAdminSounds(state: string, order: string, limit = 0, offset = 0): Promise<Sound[]>
  {
    var url = this.config.host() + this.adminItemsUrl;

    var params: string[] = [];
    if (state.length > 0) params[params.length] = "state=" + state;
    if (order.length > 0) params[params.length] = "order=" + order;
    if (offset > 0) params[params.length] = "offset=" + offset;
    if (limit > 0) params[params.length] = "limit=" + limit;
    if (params.length > 0) url = url + "?" + params.join("&");
    console.log("Querying items from: " + url);
    return this.http.get(url, this.auth.headers())
                  .toPromise()
                  .then(this.parseSounds)
                  .catch(this.handleError);
  }

  getAdminSearchSounds(search: string, order: string, limit = 0, offset = 0) : Promise<Sound[]>
  {
    if(!search)
    {
      console.error("No search term provided to getAdminSearchSounds");
      return Promise.resolve<Sound[]>([]);
    }
    var url = this.config.host() + this.adminSearchUrl + '/' + search;

    var params: string[] = [];
    if (order.length > 0) params[params.length] = "order=" + order;
    if (offset > 0) params[params.length] = "offset=" + offset;
    if (limit > 0) params[params.length] = "limit=" + limit;
    if(params.length > 0) url = url + "?" + params.join("&");

    return this.http.get(url, this.auth.headers())
      .toPromise()
      .then(this.parseSounds)
      .catch(this.handleError);
  }

  getAdminComments(): Promise<Comment[]>
  {
    let url = this.config.host() + this.adminCommentsUrl;
    return this.http.get(url, this.auth.headers())
                  .toPromise()
                  .then(response => { return response['Results']; })
                  .catch(this.handleError);
  }

  getAdminNotifications(): Promise<Note[]>
  {
    let url = this.config.host() + this.adminNotifUrl;
    return this.http.get(url, this.auth.headers())
                  .toPromise()
                  .then(response => { return response['Results'] })
                  .catch(this.handleError);
  }

  sendAdminNotification(message: string, soundUid: string, topic: string) : Promise<any>
  {
    var url = this.config.host() + this.adminNotifyUrl + "?message=" + message + "&topic=" + topic + "&uid=" + soundUid;
    return this.http.put(url, "{}", this.auth.headers())
      .toPromise()
      .then(response => { return response; } )
      .catch(this.handleError);
  }

  getAdminUsers(sort: string="", limit = 0, offset = 0) : Promise<User[]>
  {
    var url = this.config.host() + this.adminUsersUrl;
    var params: string[] = [];
    if (sort.length > 0) params[params.length] = "sort=" + sort;
    if (offset > 0) params[params.length] = "offset=" + offset;
    if (limit > 0) params[params.length] = "limit=" + limit;
    if (params.length > 0) url = url + "?" + params.join("&");

    return this.http.get(url, this.auth.headers())
      .toPromise()
      .then(response => { return response['Results']; } )
      .catch(this.handleError);
  }

  getAdminSearchUsers(search: string, limit = 0, offset = 0) : Promise<User[]>
  {
    if(!search)
    {
      console.error("No search term provided to getAdminSearchUsers");
      return Promise.resolve<User[]>([]);
    }

    var url = this.config.host() + this.adminUsersUrl;
    var params: string[] = [];
    if (search.length > 0) params[params.length] = "query=" + search.trim();
    if (offset > 0) params[params.length] = "offset=" + offset;
    if (limit > 0) params[params.length] = "limit=" + limit;
    if (params.length > 0) url = url + "?" + params.join("&");

    return this.http.get(url, this.auth.headers())
      .toPromise()
      .then(response => response['Results'])
      .catch(this.handleError);
  }

  getAdminDownloadUrl(soundUid: string, fileType: string): string
  {
    var url = this.config.host() + this.adminDownloadUrl;
    url += "/" + fileType + "/" + soundUid;
    url += "?humanize=true";
    url += "&token=" + encodeURIComponent(this.getToken());
    return url;
  }

  getAdminDownload(soundUid: string, fileType: string): Promise<any>
  {
    var url = this.config.host() + this.adminDownloadUrl;

    url += "/" + fileType + "/" + soundUid;

    return this.http.get(url, {responseType: 'blob', headers: this.auth.authHeaders()})
      .toPromise()
      .then(response => { return response; })
      .catch(this.handleError);
  }

  
  getAdminExport(fields: string, table: string, where: string, order: string, group: string, offset: number, limit: number, cache: number): Promise<any>
  {
    let url = `${this.config.host() + this.adminExportUrl}?fields=${encodeURIComponent(fields)}&table=${encodeURIComponent(table)}&where=${encodeURIComponent(where)}&order=${encodeURIComponent(order)}&group=${encodeURIComponent(group)}&offset=${offset}&limit=${limit}`
    if (cache > 0) {
      url += `&cache=${cache}`;
    }
    return this.http.get(url, this.auth.headers())
                  .toPromise()
                  .then(response => { return response['Results'] })
                  .catch(this.handleError);
  }

  getAdminLogs(offset: number, limit: number, filters: string[]): Promise<any>
  {
    let fields = "Date,Severity,Module,Message";
    let table = "log";
    let where = ""
    let order = "Date DESC";
    let group = ""
    if (offset < 0) offset = 0;
    if (limit <= 0) limit = 100;

    // build where clause from filters using REGXP on Message and Module
    if (filters.length > 0) {
      
      // escape quotes in all filters for valid sql
      filters = filters.map(f => 
        f.replace(/'/g, "\\'")
         .replace(/"/g, '\\"')
      );
      
      // join array with | separator for regexp
      const f = filters.join("|");
      where = `Module NOT REGEXP '(${f})' AND Message NOT REGEXP '(${f})'`;
    }

    return this.getAdminExport(fields, table, where, order, group, offset, limit, 0);
  }

  getAdminCall(procedure: string, params: string): Promise<any>
  {
    let url = `${this.config.host() + this.adminCallUrl}/${procedure}?params=${params}`
    //if (cache > 0) {
    //  url += `&cache=${cache}`;
    //}
    return this.http.get(url, this.auth.headers())
                  .toPromise()
                  .then(response => { return response['Results'] })
                  .catch(this.handleError);
  }

  runAdminFixup(cmd : string): Promise<string>
  {
    let url = this.config.host() + this.adminFixupUrl + "?run=" + cmd;
    return this.http.put(url, null, this.auth.headers())
               .toPromise()
               .then(response => response['Results'] as String)
               .catch(this.handleError);
  }

  getAdminTags(): Promise<Tag[]>
  {
    console.info("Gettings Tags from Market");
    let url = this.config.host() + this.adminTagUrl;
    return this.http.get(url, this.auth.headers())
               .toPromise()
               .then(response => { return response['Results'] as Tag[]; } )
               .catch(this.handleError);
  }

  addAdminTag(tag: Tag) : Promise<Tag>
  {
    let url = this.config.host() + this.adminTagUrl + "/" + tag.Value;
    return this.http.put(url, tag, this.auth.headers())
      .toPromise()
      .then(response => response['tag'] as Tag)
      .catch(this.handleError);
  }

  deleteAdminTag(tag: string): Promise<string>
  {
    let url = this.config.host() + this.adminTagUrl + "/" + tag;
    return this.http.delete(url, this.auth.headers())
      .toPromise()
      .then(response => response['tagDeleted'])
      .catch(this.handleError);
  }

  getAdminSettings(): Promise<Setting[]>
  {
    console.info("Gettings Settings from Market");
    let url = this.config.host() + this.adminSettingsUrl;
    return this.http.get(url, this.auth.headers())
               .toPromise()
               .then(response => { return response['Results'] as Setting[]; } )
               .catch(this.handleError);
  }

  putAdminSetting(setting : Setting): Promise<Setting[]>
  {
    console.info("Gettings Settings from Market");
    let url = this.config.host() + this.adminSettingsUrl;
    return this.http.put(url, setting, this.auth.headers())
               .toPromise()
               .then(response => { return response; } )
               .catch(this.handleError);
  }

  getAdminSubscriptions(offset: number, limit: number): Promise<Subscription[]>
  {
    console.info("Gettings Subscriptions from Market");
    if (offset < 0) offset = 0;
    if (limit <= 0) limit = 100;
    let url = `${this.config.host() + this.adminSubscriptionsUrl}?offset=${offset}&limit=${limit}`;
    return this.http.get(url, this.auth.headers())
               .toPromise()
               .then(response => { return response['Results'] as Subscription[]; } )
               .catch(this.handleError);
  }

  deleteAdminComment(sid: string, cid: string) : Promise<any>
  {
    let url = this.config.host() + this.commentsUrl + "/" + sid + "/" + cid;
    return this.http.delete(url, this.auth.headers())
      .toPromise()
      .then(response => { return response; } )
      .catch(this.handleError);
  }

  deleteAdminSound(soundUid: string) : Promise<string>
  {
    let url = this.config.host() + this.adminItemUrl + "/" + soundUid;
    return this.http.delete(url, this.auth.headers())
      .toPromise()
      .then(response => { return response; } )
      .catch(this.handleError);
  }

  updateAdminSound(sound: Sound) : Promise<Sound>
  {
    var soundCopy = JSON.parse(JSON.stringify(sound)); // make a copy of the sound to lowercase all keys
    for (var key in soundCopy) {
      if (soundCopy.hasOwnProperty(key)) {
        var value = soundCopy[key];
        delete soundCopy[key];
        soundCopy[key.toLowerCase()] = value;
      }
    }
    let url = this.config.host() + this.adminItemUrl + "/" + sound.Uid;
    return this.http.put(url, soundCopy, this.auth.headers()
      //{ status: "OK", updatedKey: sound.Uid, updatedSoundData: sound }
      )
      .toPromise()
      .then(response => response['updatedSoundData'])
      .catch(this.handleError);
  }

  attachAdminFile(sound: Sound, file: File) : Promise<string>
  {
    // create form data with our upload file
    let formData: FormData = new FormData();
    formData.append("file", file);

    let url = this.config.host() + this.adminAttachUrl + "/" + sound.Uid;
    return this.sendFormData(url, formData)
      .then(response => {
         console.log(response);
         return response;
      })
      .catch(this.handleError);
  }

  addAdminFile(file: File) : Promise<Sound>
  {
    // create form data with our upload file
    let formData: FormData = new FormData();
    formData.append("file", file);

    let url = this.config.host() + this.adminAddUrl;
    return this.sendFormData(url, formData)
      .then(response => {
         console.log(response);
         return response['added'] as Sound;
      })
      .catch(this.handleError);
  }

  /*
  updateAdminUserRole(user: User, role: number) : Promise<string>
  {
    let url = this.config.host() + this.adminUserRoleUrl + "/" + user.Uuid + "/" + role;
    return this.http.put(url, "", this.auth.headers())
      .toPromise()
      .then(response => response['Results'])
      .catch(this.handleError);
  }
  */

  updateAdminUser(user: any, role: number) : Promise<any> {
    let body = JSON.stringify(user);
    let url = this.config.host() + this.adminUserUpdateUrl + "/" + user.Uuid + "/" + role;
    return this.http.put(url, body, this.auth.headers())
      .toPromise()
      .then(response => response['Results'])
      .catch(this.handleError);
  }


  // helper for sending FormData in a way that will actually work with angular
  private sendFormData(url: string, form: FormData) : Promise<any>
  {
    /*
    // using http.request was the only way to get the correct multipart form header set up below
    var req = new Request({
      method: RequestMethod.Post,
      url: url,
      body: form
    });

    // set up our Content-Type header to null, which forces http to set it based on body (FormData)
    let headers = new Headers();
    headers.set('Content-Type', undefined);
    let options = new RequestOptions({ headers: headers });

    // build the request, but it won't send until we call toPromise() below (technically, subscribe(), which toPromise calls, begins the operation)
    var httpReq = this.http.request(req, options);

    // deleting from options.headers will create a malformed Content-Type header - must delete from req.headers to work properly
    req.headers.delete("Content-Type");

    return httpReq.toPromise();
    */
   return this.http.post(url, form, this.auth.headers()).toPromise();
   /*
        .map((response: Response) => {
            return response;
        }).catch(this.handleError);
        */
  }

  // -------------- general queries --------------

  getSounds(limit = 0, offset = 0): Promise<Sound[]>
  {
    let url = this.config.host() + this.itemsUrl;
    if(limit > 0 || offset > 0)
    {
      url += "?";
      if(limit > 0) url += "limit="+limit;
      if(limit > 0 && offset > 0) url += "&";
      if(offset > 0) url += "offset="+offset;
    }
    return this.http.get(url)
               .toPromise()
               .then(this.parseSounds)
               .catch(this.handleError);
  }

  getSoundsByTag(tag: string, limit = 0, offset = 0): Promise<Sound[]>
  {
    var url = `${this.config.host()}${this.itemsUrl}/tag/${tag}`;
    if(limit > 0 || offset > 0)
    {
      url += "?";
      if(limit > 0) url += "limit="+limit;
      if(limit > 0 && offset > 0) url += "&";
      if(offset > 0) url += "offset="+offset;
    }
    return this.http.get(url)
               .toPromise()
               .then(this.parseSounds)
               .catch(this.handleError);
  }

  getSoundsBySearch(search: string, limit = 0, offset = 0): Promise<Sound[]>
  {
    var url = `${this.config.host()}${this.searchUrl}/${search}`;
    if(limit > 0 || offset > 0)
    {
      url += "?";
      if(limit > 0) url += "limit="+limit;
      if(limit > 0 && offset > 0) url += "&";
      if(offset > 0) url += "offset="+offset;
    }
    return this.http.get(url)
               .toPromise()
               .then(this.parseSounds)
               .catch(this.handleError);
  }

  getSoundsByUser(user: string, limit = 0, offset = 0): Promise<UserSounds>
  {
    // pull out "DisplayName" that is delivered alongside Results
    var url = `${this.config.host()}${this.userUrl}/${user}`;
    if(limit > 0 || offset > 0)
    {
      url += "?";
      if(limit > 0) url += "limit="+limit;
      if(limit > 0 && offset > 0) url += "&";
      if(offset > 0) url += "offset="+offset;
    }
    return this.http.get(url)
               .toPromise()
               .then( response => { return { DisplayName: this.parseUser(response), Sounds: this.parseSounds(response) }; })
               .catch(this.handleError);
  }

  getSound(id: string, slug: string): Promise<Sound>
	{
    const url = `${this.config.host()}${this.itemUrl}/${id}?slug=${slug}`;
    console.info('Get sound: ' + url);
    return this.http.get(url)
      .toPromise()
      .then(response => response['Results'][0] as Sound)
      .catch(this.handleError);
  }
/*
  getGeo(latitude: number = 0, longitude: number = 0, distance: number = 0): Observable<Sound[]>
  {
    let url = this.geoUrl;

    if (latitude || longitude)
    {
      url += "/" + latitude + "/" + longitude;
      if (distance) url += "/" + distance;
    }

    url += "?limit=100";

    return this.http.get(url)
      .map(resp => this.parseSounds(resp))
      .catch(err => this.handleError(err));

    // return this.http.get(url)
    //            .toPromise()
    //            .then(this.parseSounds)
    //            .catch(this.handleError);
  }
*/
  getTags(): Promise<Tag[]>
  {
    if (this.cachedTags)
    {
        console.info("Gettings Tags from Cache");
        return Promise.resolve(this.cachedTags);
    }

    console.info("Gettings Tags from Market");
    let url = this.config.host() + this.tagsUrl;
    return this.http.get(url)
               .toPromise()
               .then(response => { this.cachedTags = response['Results'] as Tag[]; return this.cachedTags; } )
               .catch(this.handleError);
  }

  getTag(name: string): Promise<Tag>
  {
    return this.getTags().then(tags =>
    {
      for (var i = 0; i < tags.length; ++i)
      {
        var tag = tags[i];
        if (tag.Value == name)
        {
          return tag;
        }
      }

      // return a default tag
      const properName = name.charAt(0).toUpperCase() + name.slice(1);
      var t = new Tag();
      t.Value = name;
      t.Label = properName;

      return t;
    }).catch(this.handleError);
  }

  getComments(id = "", limit = 0, offset = 0): Promise<Comment[]>
  {
    let url = this.config.host() + this.commentsUrl;
    if(id) url += "/" + id;

    if(limit > 0 || offset > 0)
    {
      url += "?";
      if(limit > 0) url += "limit=" + limit;
      if(limit > 0 && offset > 0) url += "&";
      if(offset > 0) url += "offset=" + offset;
    }

    return this.http.get(url)
               .toPromise()
               .then(response => response['Results'] as Comment[] )
               .catch(this.handleError);
  }

  // -------------- authentication --------------

  /*
  login() : void
  {
    this.dialog.open(LoginDialog).afterClosed().subscribe(result =>
    {
      console.log('Dialog Result: ' + result);
      this.updateLoginState();
    });
  }
*/
  logoff() : void
  {
    this.auth.logoff();
    this.updateLoginState();

    /*
    this.auth.logoff().subscribe(result =>
    {
      console.log('Logout Result: ' + result);
      this.updateLoginState();
    });
    */
  }


  getToken() : string
  {
    if (this.authenticated == false) return null;
    return this.auth.getToken();
  }

  setToken(token : string)
  {
    this.auth.setToken(token);
    this.updateLoginState();
  }

  private getStatus(): Promise<any>
  {
    let url = this.config.host() + this.statusUrl;
    return this.http.get(url, this.auth.headers())
               .toPromise()
               .then(response => { return response; } )
               .catch(this.handleError);
  }

  private updateLoginState()
  {
    this.authenticated = this.auth.isAuthenticated();

    if (this.authenticated)
    {
       // user id is stored as "sub" in the token payload
       if (this.auth.getUser() == undefined)
       {
          console.warn("Bad token provided");
          this.auth.logoff();
          this.authenticated = false;
          this.userId = null;
          return;
       }

       this.userId = this.auth.getUser().Uuid;
       //this.userId = this.auth.getPayload().sub;

       // update status to get user name, avatar, and if admin
       this.getStatus().then(status =>
       {
          //console.info("Received status: " + JSON.stringify(status, null, 4));

          // pull out avatarUrl, displayName, isAdmin from status
          this.userId = status.Uuid;
          this.displayName = status.displayName;
          this.avatarUrl = status.avatarUrl;
          this.isAdmin = status.isAdmin;
       });
    }
    else
    {
       this.displayName = null;
       this.avatarUrl = null;
       this.isAdmin = false;
       this.userId = null;
      }
  }


  // -------------- authenticated queries --------------

  getFlags(sid: string): Promise<Flags>
  {
    console.info("Getting flags: " + sid);
    if (this.authenticated == false) return Promise.resolve({} as Flags);
    const url = `${this.config.host()}${this.flagUrl}/${sid}`;
    return this.http.get(url, {headers: this.auth.authHeaders()})
               .toPromise()
               .then( response => this.parseFlags(response['flags'] as Flag[]))
               .catch(this.handleError);
  }

  setFlag(sid: string, cid: string, value: boolean): Promise<FlagResult>
  {
    console.info("Set flag: [" + sid + "][" + cid + "] = " + value);
    if (this.authenticated == false)
    {
      return Promise.reject("Not authenticated");
    }

    var result = {} as FlagResult;
    result.Item = cid;
    result.Value = value ? 1 : 0;
    result.Success = false;

    var url = `${this.config.host()}${this.flagUrl}/${sid}`;
    if (cid.length > 0) url = url + "/" + cid;

    console.info("url="+url);

    if (value)
    {
      return this.http.post(url, {cid: cid}, {headers: this.auth.authHeaders()})
                .toPromise()
                .then( response => { result.Success = (response['Result'] === 'OK'); result.Total = response['Total']; return result; } )
                .catch(this.handleError);
    }
    else
    {
      return this.http.delete(url, {headers: this.auth.authHeaders()})
          .toPromise()
          .then( response => { result.Success = (response['Result'] === 'OK'); result.Total = response['Total']; return result; } )
          .catch(this.handleError);
    }
  }

  addComment(sid: string, cid: string, message: string): Promise<Comment>
  {
    console.info("Add comment: [" + sid + "][" + cid + "] = " + message + " url: " + url);
    if (this.authenticated == false)
    {
      return Promise.reject("Not authenticated");
    }

    var url = `${this.config.host()}${this.commentsUrl}/${sid}`;
    if (cid.length > 0) url = url + "/" + cid;

    return this.http.put(url, { message: message, heart: true })
              .toPromise()
              .then( response => response['Result'] as Comment )
              .catch(this.handleError);
  }

  removeComment(sid: string, cid: string): Promise<Comment>
  {
    console.info("Remove comment: [" + sid + "][" + cid + "]");
    if (this.authenticated == false)
    {
      return Promise.reject("Not authenticated");
    }

    var result = {} as Comment;
    result.SoundUid = sid;
    result.CommentUid = cid;

    const url = `${this.config.host()}${this.commentsUrl}/${sid}/${cid}`;

    return this.http.delete(url, {})
          .toPromise()
          .then( response => { if (response['Result'] === 'OK') return result; else return null; } )
          .catch(this.handleError);
  }

  // -------------- helpers --------------

  previewLink(sound: Sound) : string
  {
    if (sound == null || sound.PreviewUrl == null || sound.PreviewUrl.length == 0) return null;
    return this.config.host() + "/preview/" + sound.Uid;
  }

  downloadLink(sound: Sound) : string
  {
    if (this.authenticated == false || sound == null || sound.WhiteNoiseUrl == null || sound.WhiteNoiseUrl.length == 0) return null;
    return this.config.host() + "/download/" + sound.File + "?id=" + sound.Uid + "&token=" + encodeURIComponent(this.getToken());
  }

  private parseUser(response: any)
  {
      var json = response;
      var user = json.DisplayName;
      if (user == undefined || user.length == 0) user = "White Noise";
      return user;
  }

  private parseSounds(response: any)
  {
      var json = response;
      var sounds = json.Results as Sound[];

      // create shorter label and descriptions
			sounds.forEach(function(sound)
			{
				  sound.ShortLabel = sound.Label
				  if (sound.ShortLabel.length > 64)
				  {
					   sound.ShortLabel = sound.ShortLabel.slice(0,61) + '...';
				  }

				  sound.ShortDescription = sound.Description
				  if (sound.ShortDescription.length > 200)
				  {
					  sound.ShortDescription = sound.ShortDescription.slice(0,197) + '...';
          }
			});

      return sounds;
  }

  private parseFlags(flags : Flag[]): Flags
  {
    var result = {} as Flags;
    if (flags && flags.length > 0)
    {
      // conver to dictionary
      for (var i = 0; i < flags.length; ++i)
      {
        var flag = flags[i];
        result[flag.Item] = (flag.Value > 0);
      }
    }
    return result;
  }

  private handleError(err: any): Promise<any>
  {
    console.error('An error occurred', err);

    return Promise.reject(err.error || err);
  }

}
