// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
    .heart-select {
      color: red;
    }
    .mat-button {
      margin: 0 auto 0 0;
      padding: 0;
      min-width: 0;
      width: 100%;
      max-width: 160px;
    }
    .cdk-column-Actions { min-width: 80px; }
    .cdk-column-Message { padding-right: 14px; }
    .mat-row { padding: 10px 20px; }
    `, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
