// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mat-form-field 
{
    width: 100%;
}

.state-help-list
{
    font-size: 70%;
    line-height: 1.2;
    margin-top: 0px;            
}     

/* commented-out sections were trying to create a 2-columns layout in larger-width screens, but it seems not too useful and has view-encapsulation issues */

.dialog-column
{
    width: 100%;
    /* flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    margin: 0px 12px; */
}

mat-dialog-content
{
    /* display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: center;
    max-width: none;
    min-width: 0; */
}
/* 
.flex-stretch
{
    flex: 1 0 auto;
    display: inline-flex;
    flex-direction: column;
}

.flex-stretch *
{
    flex: 1 0 auto;
    display: inline-flex;
    flex-direction: column;
} */`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
