import { RouterStateSnapshot, ActivatedRouteSnapshot, Router } from '@angular/router';
import {Injectable} from '@angular/core';
import { SoundService } from './sound.service';

/**
 * Created by Ron on 03/10/2016.
 */
@Injectable()
export class AuthGuard  {
    constructor(private soundService: SoundService, private router: Router) {}
    canActivate(
        next:  ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ) {
        if (this.soundService.authenticated && this.soundService.isAdmin) { return true; }

        console.error('Not an administrator!');
        //this.router.navigateByUrl('/login');
        return false;
    }
}
